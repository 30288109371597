// Here you can add other styles@import "@coreui/coreui/scss/functions";
.sidebar {
  --cui-sidebar-nav-link-color: rgb(255 255 255 / 0.7);
  --cui-sidebar-nav-link-active-color: rgba(255, 255, 255, 0.8);
  --cui-sidebar-nav-link-hover-color: rgba(255, 255, 255, 0.9);
  --cui-sidebar-brand-height: 2.48rem;
  --cui-sidebar-header-height: 3rem;
  --cui-nav-link-font-size: 0.85rem;
  --cui-sidebar-nav-icon-font-size: 0.9rem;
  --cui-sidebar-nav-link-padding-y: 0.72rem;
  --cui-sidebar-nav-icon-width: 3.6rem;
  --cui-sidebar-narrow-width: 3.6rem;
  --cui-sidebar-nav-icon-height: 1.2rem;
  font-family: "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.header {
  --cui-header-min-height: 0rem;
  --cui-header-padding-x: 0rem;
  --cui-header-padding-y: 0.055rem;
  --cui-subheader-min-height: 2.7rem;
  --cui-header-nav-link-padding-x: 0.2rem;
  --cui-header-nav-link-padding-y: 0.1rem;
  //background-color: #2c6e6e;
  //background-color: #1e3c5f;
  background-color: #032e54;
}

.header-center .ag-header-cell-label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-nav .nav-link {
  font-size: 0.92em;
}

.sidebar-header .nav-link {
  min-height: 3.75em;
}

div.header-content {
  position: sticky;
  top: 38px;
  z-index: 100;
  //background-color: rgba(91, 136, 131, 0.22) !important;
  background-color: #efefef !important;
  //background-color: #738fd52b !important;
  box-shadow: 0 0 0 0;
  //border: 1px solid #d2d2d2;
  border: 1px solid rgba(0, 0, 21, 0.175);
  margin-bottom: 8px;
  padding-left: 16px;
}

.popup-no-scrollbars {
  overflow: hidden;
}

div.header-content-popup {
  background-color: #efefef !important;
  box-shadow: 0 0 0 0;
  border: 1px solid rgba(0, 0, 21, 0.175);
  margin-bottom: 8px;
  padding-left: 16px;
}

//좌측 미니멈 사이드바에서 스크롤큰거 생기는거방지
.sidebar-nav {
  overflow-y: hidden !important;
}

.sidebar-narrow-unfoldable:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 3.6rem;
}

pre {
  font-family: "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: 200px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 100% !important;
  }
}

.ag-theme-balham {
  --ag-font-size: 18px !important;
  --ag-font-family: monospace;
}

.ag-theme-alpine,
.ag-theme-alpine-dark {
  --ag-grid-size: 3px;
  --ag-list-item-height: 20px;
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell,
.ag-theme-alpine-dark .ag-header-cell,
.ag-theme-alpine-dark .ag-header-group-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine-dark .ag-cell,
.ag-theme-alpine-dark .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.form-control {
  line-height: 0.8;
}

.ag-theme-alpine div.ag-row,
.ag-theme-alpine-dark div.ag-row {
  font-size: 13px !important;
}

.ag-center-cols-container {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  //text-align: center;
}

.ag-right-header .ag-header-cell-label {
  flex-direction: row-reverse;
}

.ag-center-header .ag-header-cell-label {
  justify-content: center;
}

.ag-left-header .ag-header-cell-label {
  flex-direction: row;
}

.ag-center-header-lg .ag-header-cell-label {
  justify-content: center;
  font-size: 1.1em;
  font-weight: bold;
}

.centerCell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.alignCenterCell {
  display: flex;
  align-items: center;
}

//ag-row display flex 하면 autoHeigth 작동 안함
.ag-row-group {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.ag-center-cell {
  text-align: center;
}

.ag-right-cell {
  text-align: right;
}

.ag-left-cell {
  text-align: left;
}

.ag-picker-field-wrapper {
  min-height: 32px !important;
  //min-width: 100px !important;
}

.ag-cell-inline-editing {
  height: 50px !important;
  margin-top: 5px !important;
}

.gridAnswer .ag-cell-focus,
.gridAnswer .ag-cell {
  border: none !important;
}

.ag-theme-alpine .ag-paging-panel,
.ag-theme-alpine-dark .ag-paging-panel {
  height: 38px !important;
}

.ag-cell {
  display: flex !important;
  align-items: center !important;
}

.grid-cell .ag-body-viewport {
  overflow-y: hidden !important;
}

.bigHeight .ag-cell-value {
  padding: "5px 0 5px 0";
  line-height: 60px !important;
}

.bigHeight .ag-selection-checkbox {
  padding: "5px 0 5px 0";
  height: 60px !important;
  line-height: 60px !important;
}

.agGridNoBorder .ag-row {
  border-bottom-style: none !important;
}

.agGridNoBorder .ag-theme-alpine .ag-row-odd {
  background-color: #fff !important;
}

.agGridNoBorder .ag-theme-alpine-dark .ag-row-odd {
  background-color: #181d1f !important;
}

.agGridNoBorder .ag-unselectable {
  -webkit-user-select: auto;
  user-select: auto !important;
}

.ag-header-row-column {
  background-color: #738fd52b;
}

.grid-cell .ag-header-row-column {
  background-color: #34997f2b;
}

.fileGrid .ag-header-row-column {
  background-color: #738fd52b;
}

.ag-column-drop-horizontal {
  height: 36px !important;
}

//checkbox global 설정임.
// .ag-selection-checkbox {
//   display: flex;
//   align-items: center;
// }
// .ag-row-level-0 {
//   background-color: #cc222244 !important;
// }

// .form-multi-select-option .form-multi-select-option-with-checkbox {
//   width: 20px;
//   height: 20px;
// }

// .form-multi-select-selection-cleaner {
//   display: hidden;
// }

// .form-multi-select-options {
//   width: 100px
// }

.valid {
  color: red;
}

.calendar-grid {
  width: 25vw;
  margin-top: 3px;
}

/*
.ag-row-level-0 {
  background-color: #fff !important;
}
.ag-row-level-1 {
  background-color: #eee !important;
}
.ag-row-level-2 {
  background-color: #ddd !important;
}*/

/*
.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell,
.ag-theme-alpine-dark .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.ag-theme-alpine .ag-cell, .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group,  
.ag-theme-alpine-dark .ag-cell, .ag-theme-alpine-dark .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 0px !important;
  padding-right: 0px !important;
}*/

.rag-amber {
  background-color: lightsalmon;
}

.rag-red-outer {
  background-color: lightcoral;
}

.rag-red {
  /* background-color: red; */
  border: 3px solid red !important;
}

.rag-green-outer .rag-element {
  background-color: lightgreen;
}

.headerNavLink {
  margin: 0 10px 0 10px;
  font-weight: bold;
  font-family: "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.nav-underline .nav-link,
.nav-underline .nav-link:hover {
  border-bottom: 1px solid transparent;
}

.nav-link.active {
  background-color: rgb(117 179 204 / 25%) !important;
  color: #0aa895cc !important;
  font-weight: bold;
}

.icon-hover:hover {
  color: #fff !important;
}

.nav-link h3:hover {
  color: #fff !important;
  border-bottom: 1px solid #fff;
}

.header-nav .nav-underline {
  border-bottom: 0px;
}

.sidebar-header .nav-underline {
  border-bottom: 1px solid var(--cui-nav-underline-border-color, #c4c9d0);
}

.nav-underline {
  border-bottom: 0px;
}

html {
  overflow-y: scroll;
}

.modal {
  display: block !important;
}

.modal-content {
  border-radius: 0.2 !important;
}

.modal-open {
  overflow: visible !important;
  //padding-right: 15px !important;
}

.modal-body {
  overflow-y: scroll !important;
}

.customAccordHeader .accordion-button {
  font-size: 0.9em;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  line-height: 26px !important;
}

span.sp-pad {
  display: inline-block !important;
  margin: 7px !important;
  line-height: 18px !important;
}

.cardWrap {
  box-shadow: none;
  //border-top: 4px solid #4f5d73 !important;
  //border: 1px solid #d2d2d2 !important;
  border: 0px solid rgba(0, 0, 21, 0.24) !important;
}

.cardWrapIcon {
  margin: 4px 4px -2px 0;
  vertical-align: -0.05rem;
  --ci-primary-color: #eee;
}

.cardHeader {
  border-radius: 1;
  line-height: 30px;
  height: 38px;
  font-size: 1em;
  background-color: #0b5d6deb !important;
  //border-bottom: 1px solid rgba(0, 0, 21, 0.175) !important;
  color: #eee;
}

.cardHeader-v {
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: 0.8em;
}

.zoom {
  //width: 180px;
  overflow: hidden;
  height: 160px;
  line-height: 30px;
  font-weight: bold;
  transform: scale(1);
  transition: transform 0.1s;

  .zoomFoot {
    transform: translate(0, 0);
    transition: transform 0.2s;
  }
}

.zoom:hover {
  //width: 180px;
  overflow: hidden;
  height: 160px;
  line-height: 30px;
  font-weight: bold;
  transform: scale(1.06);
  transition: transform 0.1s;

  .zoomFoot {
    //transform: translate(0, -30px);
    transition: transform 0.2s;
  }
}

.zoomFootText {
  font-size: 0.8em;
  width: 100%;
  height: 30px;
  line-height: 150px;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0px;
  left: 0;
  color: #fff;
}

.zoomFootText2 {
  font-size: 0.8em;
  width: 100%;
  height: 20px;
  line-height: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0px;
  left: 0;
  color: #fff;
}

.divSelf {
  font-size: 0.8em;
  width: 100%;
  height: 30px;
  line-height: 150px;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0px;
  left: 0;
  color: #fff;
}

.zoomIcon {
  width: 2.8rem !important;
  height: 2.8rem !important;
  margin-right: 5px !important;
  margin-top: -36px !important;
  color: #444 !important;
}

.fs-7 {
  font-size: 0.82rem;
  line-height: 20px;
  min-height: 28px !important;
  padding: 0.2rem 0.43rem;
}

.fs-content {
  font-size: 0.82rem;
  line-height: 20px;
  min-height: 60px !important;
  //min-height: 140px !important;
  height: 100%;
  padding: 0.2rem 0.43rem;
}

.lh-13 {
  line-height: 12px;
  min-height: 12px;
  font-size: 0.82rem;
  /*padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;*/
}

.lh-15 {
  line-height: 20px;
  min-height: 30px;
  font-size: 0.82rem;
  /*padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;*/
}

.lh-18 {
  line-height: 15px;
  min-height: 15px;
  font-size: 0.82rem;
  /*padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;*/
}

.lh-20 {
  width: 180px !important;
  line-height: 15px;
  min-height: 15px;
  font-size: 0.82rem;
  /*padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;*/
}

.sf {
  padding-left: 4px !important;
  font-size: 0.82rem;
  vertical-align: middle;
  height: 34px !important;
  //background-color: #fff !important;
}

.sf-xxl {
  width: 300px;
  padding-left: 4px !important;
  font-size: 0.82rem;
  vertical-align: middle;
  height: 34px !important;
  //background-color: #fff !important;
}

.sf-padding {
  padding-left: 12px !important;
  font-size: 0.82rem;
  vertical-align: middle;
  height: 38px !important;
  //background-color: #fff !important;
}

.sf-padding-sm {
  padding-left: 12px !important;
  width: 10%;
  font-size: 0.82rem;
  vertical-align: middle;
  height: 38px !important;
  //background-color: #fff !important;
}

.sf-long {
  // width: 25px;
  // padding-left: 12px !important;
  // font-size: 0.82rem;
  // vertical-align: middle;
  // height: 34px !important;
  width: 20px;
  padding-left: 12px !important;
  font-size: 0.82rem;
  vertical-align: middle;
  height: 34px !important;
}

.sf-kr {
  padding-left: 20px !important;
  font-size: 0.82rem;
  vertical-align: middle;
  height: 34px !important;
  background-color: var(--cui-card-cap-bg) !important;
}

.sf-label-min {
  width: 10%;
  padding-left: 10px !important;
  font-size: 0.82rem;
  font-weight: bold;
  vertical-align: middle;
  line-height: 20px;
  //background-color: var(--cui-card-cap-bg) !important;
  background-color: rgb(115 115 115 / 10%) !important;
}

.sf-label-sm {
  width: 80px;
  padding-left: 10px !important;
  font-size: 0.82rem;
  font-weight: bold;
  vertical-align: middle;
  //background-color: var(--cui-card-cap-bg) !important;
  background-color: rgb(115 115 115 / 10%) !important;
}

.sf-label {
  width: 100px;
  padding-left: 10px !important;
  font-size: 0.82rem;
  font-weight: bold;
  vertical-align: middle;
  //background-color: var(--cui-card-cap-bg) !important;
  background-color: rgb(115 115 115 / 10%) !important;
}

.sf-label-xxl {
  // width: 15%;
  width: 220px;
  padding-left: 10px !important;
  font-size: 0.82rem;
  font-weight: bold;
  vertical-align: middle;
  //background-color: var(--cui-card-cap-bg) !important;
  background-color: rgb(115 115 115 / 10%) !important;
}

.sf-label-long {
  //width: 4vw;
  width: 68px;
  padding-left: 12px !important;
  font-size: 0.82rem;
  font-weight: bold;
  vertical-align: middle;
  //background-color: var(--cui-card-cap-bg) !important;
  background-color: rgb(115 115 115 / 10%) !important;
}

.sf-label-scd {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgb(115 115 115 / 10%) !important;
}

.header-label {
  background-color: rgb(115 115 115 / 10%) !important;
  font-size: 1.2em;
  font-weight: bold;
}

.fit-cell {
  width: 63vw;
}

.event {
  color: red;
  background-color: blue;
}
/*
.table-light {
  --cui-table-color: rgba(44, 56, 74, 0.95);
  --cui-table-bg: #f6faff;
  --cui-table-border-color: rgba(218, 220, 224, 0.995);
  --cui-table-striped-bg: rgba(226, 229, 232, 0.9975);
  --cui-table-striped-color: rgba(44, 56, 74, 0.95);
  --cui-table-active-bg: rgba(218, 220, 224, 0.995);
  --cui-table-active-color: rgba(44, 56, 74, 0.95);
  --cui-table-hover-bg: rgba(222, 225, 228, 0.99625);
  --cui-table-hover-color: rgba(44, 56, 74, 0.95);
  color: var(--cui-table-color);
  border-color: var(--cui-table-border-color);
}*/

.table-light {
  --cui-table-color: rgba(44, 56, 74, 0.95);
  --cui-table-bg: #fff;
  --cui-table-border-color: rgba(218, 220, 224, 0.995);
  --cui-table-striped-bg: rgba(226, 229, 232, 0.9975);
  --cui-table-striped-color: rgba(44, 56, 74, 0.95);
  --cui-table-active-bg: rgba(218, 220, 224, 0.995);
  --cui-table-active-color: rgba(44, 56, 74, 0.95);
  --cui-table-hover-bg: rgba(222, 225, 228, 0.99625);
  --cui-table-hover-color: rgba(44, 56, 74, 0.95);
  color: var(--cui-table-color);
  border-color: var(--cui-table-border-color);
}

.searchOptionLabel .form-check-label {
  font-size: 0.8em;
  margin: 4px 0 0 -4px;
}

.form-control-sm {
  padding: 0.25rem 0.5rem 0.25em 0.5em;
}

.text-area-noborder {
  border-radius: 0px !important;
}

.invalid-message {
  margin: 0 4px 0 4px;
  display: inline-block;
  font-size: 1em;
  color: #db2020;
}

.form-check-input {
  background-color: var(--cui-form-select-disabled-bg, #fafbfc);
  border-color: var(--cui-form-select-disabled-border-color, #9b9fa7);
}

.form-select {
  width: 160px;
  font-weight: bold;
}
.form-select-sm {
  width: 90px;
  font-weight: bold;
}
.form-select-lg {
  width: 164px;
  font-weight: bold;
}

//s: Calendar
.reguCalendar .form-control,
.timePicker .form-control {
  font-size: 0.8rem !important;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
}

.monthCalendar {
  font-size: 0.8em;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  border-radius: 5px;
  width: 100%;
}

.monthCalendarInTable {
  font-size: 1em;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  border-radius: 5px;
  width: 100%;
}

.yearCalendarInTable {
  font-size: 1em;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  border-radius: 5px;
  width: 100%;
  height: 32px;
}

.monthCalendarInGrid {
  font-size: 1em;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  border-radius: 5px;
  width: 100%;
  line-height: 25px;
}

.react-datepicker__navigation {
  top: 10px !important;
}

.react-datepicker__header {
  top: 3px !important;
}

.react-datepicker__calendar-icon {
  width: 1.2em !important;
  height: 1.2em !important;
}

.tbl .react-datepicker__calendar-icon {
  width: 2.5em !important;
  height: 2.5em !important;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  opacity: 0.6;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.gridList .calendar {
  --cui-calendar-table-cell-size: 1.75rem;
  --cui-calendar-nav-icon-width: 0.8rem;
  --cui-calendar-nav-icon-height: 0.8rem;
}

.gridList .calendar .btn-sm,
.gridList .btn-group-sm > .btn {
  --cui-btn-padding-y: 0.25rem;
  --cui-btn-padding-x: 0.1.5rem;
  --cui-btn-font-size: 0.675rem;
}

.gridList .input-group-text {
  padding: 0.375rem 0.35rem;
}

.gridList .dropdown-menu {
  --cui-dropdown-font-size: 0.8rem !important;
}

.gridList .input-group-sm > .form-control,
.gridList .input-group-sm > .form-select,
.gridList .input-group-sm > .input-group-text,
.gridList .input-group-sm > .btn {
  padding: 0.2rem 0.5rem;
}

.gridList .form-control {
  padding: 0.375rem 0.5rem !important;
}

.date-picker-arrow-icon {
  margin-right: 14px;
}
//e: Calendar

.btn:not(.placeholder):not([class*="link"]):not([class*="outline"]):not([class*="ghost"]):not([class*="transparent"]):not([class*="close"]) {
  box-shadow: 0 0 0 0;
}

.btn-sm,
.btn-group-sm > .btn {
  --cui-btn-padding-y: 0.25rem;
  --cui-btn-font-size: 0.875rem;
}

.btnGroup {
  border-radius: 4px;
  padding: 4px 14px;
  margin-bottom: 5px;
}

.fXAXjb {
  padding: 1px;
}

.compType .form-check-label {
  font-size: 0.8em;
}

//s:Splitter
.__dbk__gutter.Dark {
  background: #8c8c98 !important;
  height: 500px;
}

.__dbk__gutter.Horizontal {
  padding: 0 !important;
}

.__dbk__dragger.Horizontal {
  width: 0px !important;
  height: 39px !important;
}

.__dbk__child-wrapper {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
//e:Splitter

//CCM
.ccm_background {
  background-color: blue !important;
}

//Log
.logCols .ag-icon-filter {
  display: none;
}

/* customHeadeGroup  start------------------- */
.customHgExpandButton {
  float: right;
  margin-top: 2px;
  margin-left: -2px;
  width: 28px;
  line-height: 25px;
  text-align: center;
  //border: 1px solid silver;
  cursor: pointer;
}

.custom_hg_expanded {
  animation-name: toCustomHgExpanded;
  animation-duration: 0.6s;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.custom_hg_collapsed {
  //color: cornflowerblue;
  color: #be76f9;
  animation-name: toCustomHgCollapsed;
  animation-duration: 0.6s;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.customHeaderMenuButton,
.customHeaderLabel,
.customSortDownLabel,
.customSortUpLabel,
.customSortRemoveLabel {
  margin-top: 2px;
  margin-left: 4px;
  float: left;
}

.customSortDownLabel {
  margin-left: 10px;
}

.customSortUpLabel {
  margin-left: 1px;
}

.customSortRemoveLabel {
  float: left;
  font-size: 11px;
}

/*
.active {
  //color: cornflowerblue;
  color: #be76f9;

}
*/
@keyframes toCustomHgExpanded {
  from {
    //color: cornflowerblue;
    color: #be76f9;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    color: black;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes toCustomHgCollapsed {
  from {
    color: black;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  to {
    //color: cornflowerblue;
    color: #be76f9;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
/* customHeadeGroup  end------------------- */
/* HeadeGroup bg color start--------------- */
/*
body:not(.dark-theme) .cs_column-group-blue {
  background-color: #e2edf5;
}

body.dark-theme .cs_column-group-blue {
  background-color: #a6c5f7; //7a83cc
}
*/

.cs_column-group-blue {
  background-color: #3db2ff30;
}
/*
body:not(.dark-theme) .cs_column-group-yellow {
  background-color: #f4f4df;
}

body.dark-theme .cs_column-group-yellow {
  background-color: #f0daa9; //b8ad6b
}
*/

.cs_column-group-yellow {
  background-color: #ffff7326;
}

/*
body:not(.dark-theme) .cs_column-group-green {
  background-color: #eff9ec;
}

body.dark-theme .cs_column-group-green {
  background-color: #9ed8b1; //337676
}
*/

.cs_column-group-green {
  background-color: #7bf15724;
}
/*
body:not(.dark-theme) .cs_column-group-pink {
  background-color: #f1e2f4;
}

body.dark-theme .cs_column-group-pink {
  background-color: #dca9f0; //af88ad
}
*/
.cs_column-group-pink {
  background-color: #e35aff24;
}

/* HeadeGroup bg color end---------------- */
/* custom Label  start-------------------- */
.cs_en_title {
  font-size: 1.2rem;
  //font-weight: bolder;
  //color:#1d177b;
}

.cs_kr_title {
  font-size: 1rem;
  font-weight: bolder;
  margin-left: 10px;
  color: #8a93a2;
}

.cs_en_card_title {
  font-size: 1rem;
  font-weight: bolder;
}

.cs_kr_card_title {
  font-size: 1rem;
  font-weight: bolder;
  margin-left: 10px;
  color: #8a93a2;
}

.cs_card_notice {
  font-size: 0.8rem;
  margin-left: 4px;
  color: #01a78f;
}

//agRichSelect css 오버라이드
div.ag-rich-select .ag-rich-select-list {
  width: 100%;
  min-width: 200px;
  height: 100% !important;
}

// .ag-row-hover {

//   background-color: #db2020 !important;
// }

/* custom Label  end------------------- */

/* Dashboard start --------------------- */

.authWrap {
  padding-left: 6px;
  font-size: 1em;
  font-weight: bold;
  height: 26px;
  line-height: 26px;
}

.dashCalendar .fc {
  --fc-border-color: var(--cui-border-color, #babfc7);
}

.dashCalendar .fc-theme-standard .fc-scrollgrid {
  border: 1px solid #babfc7 !important;
  border-radius: 3px;
}

.dashCalendar .fc .fc-scrollgrid-section > td {
  border-bottom: 0px !important;
}

.dashCalendar .fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0em;
}

.dashCalendar .fc .fc-toolbar-title {
  font-size: 1em;
  margin: 0;
  //position: absolute;
  //top: 8px;
  //left: 132px;
  color: #444;
  font-weight: bold;
}

.dashCalendar .fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  font-size: 0.9em;
}

.dashCalendar .fc .fc-button {
  padding: 0 2px 3px 2px;
}

.dashCalendar .fc-more-popover {
  // position: absolute;
  // top: -150px !important;
  // height: 500px !important;
  // left: -100px !important;
  z-index: 1 !important;
  overflow: auto !important;
}

.dashCalendar .fc-scroller-liquid-absolute {
  height: 362px;
}

.dashCalendar .fc-daygrid-more-link {
  bottom: 2px !important;
  //left: 34px !important;
}

.dashCalendar .fc-button {
  font-size: 0.8em !important;
  margin: 0px 5px 5px 5px !important;
}

.dashCardHeader {
  background-color: #0b5d6deb;
  //background-color: #0b8384;
  border-radius: 3px;
  //color: #333;
  color: #eee;
  padding-left: 20px;
  line-height: 34px;
  //border: 1px solid silver;
  border: 0;
}

.dashboardActivityTabNav .nav-item {
  width: 12.5%;
}

body:not(.dark-theme) .dashboardActivityTabNav .active {
  background-color: #f0f9ff !important;
}

.dashboardReguTabNav .nav-item {
  width: 25%;
}

//body.dark-theme .dashboardReguTabNav .active {
body:not(.dark-theme) .dashboardReguTabNav .active {
  background-color: #89c2ff61 !important;
}

.dashboardNotice .ag-header {
  display: none;
}

.dashboardCard {
  border-left: 1px solid #babfc7 !important;
  border-right: 1px solid #babfc7 !important;
  border-bottom: 1px solid #babfc7 !important;
}

.dashboardCard .card-header {
  background-color: rgba(0, 0, 21, 0) !important;
}

.row01-strong {
  background-color: #6fb1dd21 !important;
}

.row02-strong {
  background-color: #00a97736 !important;
}

.row03-strong {
  background-color: #fd47472b !important;
}

body:not(.dark-theme) .dashboardCount {
  color: #53545e;
  font-size: 1.6rem;
  line-height: 1.2em;
  cursor: pointer;
}

body.dark-theme .dashboardCount {
  color: #dcdfe4;
  font-size: 1.6rem;
  line-height: 1.2em;
  cursor: pointer;
}

.dashboardCountZero {
  color: #768192;
  font-size: 1.8rem;
  line-height: 1.2em;
}

.progress {
  --cui-progress-height: 0.6rem;
}

div.countBox:hover {
  background-color: #ceeced !important;
}

body.dark-theme div.countBox:hover {
  background-color: #444 !important;
}

/* Dashboard end --------------------- */

/* s: Color Change --------------------- */
.bg-success-gradient {
  background-color: var(--cui-success, #409287);
  background-image: linear-gradient(45deg, var(--cui-success-start, #409287) 0%, var(--cui-success-stop, #409287 100%));
}

/*
.btn-primary {
  --cui-btn-color: hsla(0,0%,100%,.87);
  --cui-btn-bg: #321fdb;
  --cui-btn-border-color: #321fdb;
  --cui-btn-hover-color: hsla(0,0%,100%,.87);
  --cui-btn-hover-bg: #5141e0;
  --cui-btn-hover-border-color: #4735df;
  --cui-btn-focus-shadow-rgb: 75,58,223;
  --cui-btn-active-color: hsla(0,0%,100%,.87);
  --cui-btn-active-bg: #5b4ce2;
  --cui-btn-active-border-color: #4735df;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0,0,21,.125);
  --cui-btn-disabled-color: hsla(0,0%,100%,.87);
  --cui-btn-disabled-bg: #321fdb;
  --cui-btn-disabled-border-color: #321fdb;
  */
/*
.btn-primary {
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #337676;
  --cui-btn-border-color: #337676;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #327e7e;
  --cui-btn-hover-border-color: #397979;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #3e9292;
  --cui-btn-active-border-color: #306e6e;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #337676;
  --cui-btn-disabled-border-color: #337676;
}
*/

.btn-primary {
  --cui-btn-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-bg: #2b4b7e;
  --cui-btn-border-color: #321fdb;
  --cui-btn-hover-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-hover-bg: #3e5e91;
  --cui-btn-hover-border-color: #4735df;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-active-bg: #4e5e91;
  --cui-btn-active-border-color: #4735df;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-disabled-bg: #321fdb;
  --cui-btn-disabled-border-color: #321fdb;
}

/*
.btn-line {
  color: #4e4e4e;
  box-shadow: none !important ;
  border: 6px #666 solid !important;
  --cui-btn-color: rgba(255, 255, 255, 0.71);
  --cui-btn-bg: #fff;
  //--cui-btn-hover-color: rgb(112, 155, 154);
  --cui-btn-hover-bg: #d3eeed;
  --cui-btn-hover-border-color: #618484 !important;
  --cui-btn-active-color: rgba(110, 110, 110, 0.87);
  --cui-btn-active-bg: #fefefe;
  --cui-btn-active-border-color: #306e6e;
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #e3e3e3;
  --cui-btn-disabled-border-color: #717171;
}*/

/*
.btn-line {
  color: #4e4e4e;
  box-shadow: none !important ;
  border: 6px #8c8c8c solid !important;
  border-radius: 1;
  --cui-btn-color: rgba(255, 255, 255, 0.71);
  --cui-btn-bg: #fff;
  //--cui-btn-hover-color: rgb(112, 155, 154);
  --cui-btn-hover-bg: #d3eeed;
  --cui-btn-hover-border-color: #618484 !important;
  --cui-btn-active-color: rgba(110, 110, 110, 0.87);
  --cui-btn-active-bg: #fefefe;
  --cui-btn-active-border-color: #306e6e;
  --cui-btn-disabled-color: rgba(91, 89, 89, 0.9);
  --cui-btn-disabled-bg: #e3e3e3;
  --cui-btn-disabled-border-color: #717171;
}*/

.dashIconWrap {
  border: 1px solid #fff;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 15px;
  left: 70px;
  border-radius: 100px;
}

.btn-line {
  color: #4e4e4e;
  box-shadow: 4px 4px 10px 0 #aaa !important;
  border-top: 10px #325866e3 solid !important;
  border-bottom: 10px #325866e3 solid !important;
  border-left: 2px #325866e3 solid !important;
  border-right: 2px #325866e3 solid !important;
  border-radius: 20px;
  --cui-btn-color: rgba(255, 255, 255, 0.71);
  --cui-btn-bg: #bbdde64d;
  //--cui-btn-hover-color: rgb(112, 155, 154);
  --cui-btn-hover-bg: #1b93783b;
  --cui-btn-hover-border-color: #115454 !important;
  --cui-btn-active-color: rgba(110, 110, 110, 0.87);
  --cui-btn-active-bg: #0e5b4a3b;
  --cui-btn-active-border-color: #0d3e3e;
  --cui-btn-disabled-color: rgba(91, 89, 89, 0.9);
  --cui-btn-disabled-bg: #e3e3e3;
  --cui-btn-disabled-border-color: #717171;
  font-size: 1.1em;
}

.btn-line:hover {
  border-top: 10px #203e48e3 solid !important;
  border-bottom: 10px #203e48e3 solid !important;
  border-left: 2px #203e48e3 solid !important;
  border-right: 2px #203e48e3 solid !important;
}

.btn-miniLine {
  color: #4e4e4e;
  box-shadow: 4px 4px 10px 0 #aaa !important;
  border-top: 7px #325866e3 solid !important;
  border-bottom: 7px #325866e3 solid !important;
  border-left: 2px #325866e3 solid !important;
  border-right: 2px #325866e3 solid !important;
  border-radius: 15px;
  --cui-btn-color: rgba(255, 255, 255, 0.71);
  --cui-btn-bg: #bbdde64d;
  //--cui-btn-hover-color: rgb(112, 155, 154);
  --cui-btn-hover-bg: #1b93783b;
  --cui-btn-hover-border-color: #115454 !important;
  --cui-btn-active-color: rgba(110, 110, 110, 0.87);
  --cui-btn-active-bg: #0e5b4a3b;
  --cui-btn-active-border-color: #0d3e3e;
  --cui-btn-disabled-color: rgba(91, 89, 89, 0.9);
  --cui-btn-disabled-bg: #e3e3e3;
  --cui-btn-disabled-border-color: #717171;
  font-size: 1.1em;
}

.btn-miniLine:hover {
  border-top: 7px #203e48e3 solid !important;
  border-bottom: 7px #203e48e3 solid !important;
  border-left: 2px #203e48e3 solid !important;
  border-right: 2px #203e48e3 solid !important;
}

.btn-biz {
  color: #4e4e4e;
  box-shadow: 4px 4px 10px 0 #aaa !important;
  border-top: 10px #325866e3 solid !important;
  border-bottom: 10px #325866e3 solid !important;
  border-left: 2px #325866e3 solid !important;
  border-right: 2px #325866e3 solid !important;
  border-radius: 20px;
  --cui-btn-color: rgba(255, 255, 255, 0.71);
  --cui-btn-bg: #cab7e04d;
  //--cui-btn-hover-color: rgb(112, 155, 154);
  --cui-btn-hover-bg: #ffa1d63b;
  --cui-btn-hover-border-color: #115454 !important;
  --cui-btn-active-color: rgba(110, 110, 110, 0.87);
  --cui-btn-active-bg: #c26e9e3b;
  --cui-btn-active-border-color: #0d3e3e;
  --cui-btn-disabled-color: rgba(91, 89, 89, 0.9);
  --cui-btn-disabled-bg: #e3e3e3;
  --cui-btn-disabled-border-color: #717171;
  font-size: 1.1em;
}

.btn-biz:hover {
  border-top: 10px #203e48e3 solid !important;
  border-bottom: 10px #203e48e3 solid !important;
  border-left: 2px #203e48e3 solid !important;
  border-right: 2px #203e48e3 solid !important;
}

//12 12
.lh-19 {
  line-height: 16px;
  min-height: 12px;
  font-size: 0.8rem;
  /*padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;*/
}

.dark-theme .btn-primary {
  --cui-btn-color: #000015;
  --cui-btn-bg: #337676;
  --cui-btn-border-color: #337676;
  --cui-btn-hover-color: #000015;
  --cui-btn-hover-bg: #327e7e;
  --cui-btn-hover-border-color: #397979;
  --cui-btn-focus-shadow-rgb: 113, 106, 177;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #3e9292;
  --cui-btn-active-border-color: #306e6e;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #000015;
  --cui-btn-disabled-bg: #337676;
  --cui-btn-disabled-border-color: #337676;
}

.form-check-input:checked {
  background-color: var(--cui-form-check-input-checked-bg-color, #337676);
  border-color: var(--cui-form-check-input-checked-border-color, #337676);
}

.dark-theme .form-check-input:checked {
  background-color: #337676;
  border-color: #337676;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.7;
}

.form-switch .form-check-input:focus {
  background-image: var(
    --cui-form-switch-focus-bg-image,
    url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23619999%27/%3e%3c/svg%3e")
  );
}

.form-check-input:focus {
  border-color: #86acac;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(75 118 118 / 25%);
}

.accordion {
  --cui-accordion-border-radius: 0rem !important;
}

.accordion-header {
  line-height: 30px;
}

.accordion-button {
  border-radius: 0rem !important;
  background-color: rgba(91, 136, 131, 0.22);
  //background-color: #23896f2b;
  box-shadow: inset 0 calc(-1 * var(--cui-accordion-border-width)) 0 var(--cui-accordion-border-color);
}

.accordion-button:not(.collapsed) {
  color: #181d1f;
  background-color: #dae5e7;
  box-shadow: inset 0 calc(-1 * var(--cui-accordion-border-width)) 0 var(--cui-accordion-border-color);
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--cui-input-focus-border-color, #95cbcb);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(9, 71, 52, 0.55);
}

.dark-theme .accordion-button:focus {
  z-index: 3;
  border-color: var(--cui-input-focus-border-color, #121b1b);
  outline: 0;
  color: #ccc;
  box-shadow: 0 0 0 0.2rem rgba(9, 50, 71, 0.55);
}

.dark-theme .accordion-button:is(.collapsed) {
  color: #e9ebeb;
  background-color: #393a4f;
  box-shadow: inset 0 calc(-1 * var(--cui-accordion-border-width)) 0 var(--cui-accordion-border-color);
}

.dark-theme .accordion-button:not(.collapsed) {
  color: #e9ebeb;
  background-color: #282933;
  box-shadow: inset 0 calc(-1 * var(--cui-accordion-border-width)) 0 var(--cui-accordion-border-color);
}

.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  color: #337676;
  background: transparent;
  border-color: #337676;
}

.table-primary {
  --cui-table-bg: #f1f1f1;
}

.dark-theme .dark\:table-primary,
.dark-theme .table-primary:not([class*="dark:"]) {
  --cui-table-bg: #263c3e;
  --cui-table-color: #ededed;
}

.form-control:focus,
.form-select:focus .form-select-sm:focus {
  border-color: var(--cui-input-focus-border-color, #95cbcb);
  background-color: var(--cui-input-focus-bg, #fff);
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(15, 132, 97, 0.25);
}

.form-control:disabled {
  //background-color: #f1f2f7;
  //border-color: #bdc3cd;
  border: 1px solid #fff;
  background-color: #fff;
  opacity: 1;
}

.picker.show .input-group {
  border-color: var(--cui-input-focus-border-color, #95cbcb);
  background-color: var(--cui-input-focus-bg, #fff);
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(15, 132, 97, 0.25);
}

.ag-overlay {
  top: 13px !important;
}

.btn-blue {
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #0c4f81;
  --cui-btn-border-color: #064575;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #0a5a97;
  --cui-btn-hover-border-color: #064575;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #395b75;
  --cui-btn-active-border-color: #306e6e;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #337676;
  --cui-btn-disabled-border-color: #337676;
}

.btn-dark {
  --cui-btn-color: rgba(255, 255, 255, 0.87);
  --cui-btn-bg: #364867;
  --cui-btn-border-color: #4f5d73;
  --cui-btn-hover-color: #fff;
  --cui-btn-hover-bg: #3f557b;
  --cui-btn-hover-border-color: #616d81;
  --cui-btn-focus-shadow-rgb: 100, 112, 132;
  --cui-btn-active-color: #fff;
  --cui-btn-active-bg: #727d8f;
  --cui-btn-active-border-color: #616d81;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 0.87);
  --cui-btn-disabled-bg: #4f5d73;
  --cui-btn-disabled-border-color: #4f5d73;
}

.dark-theme .btn-blue {
  --cui-btn-color: #eee;
  --cui-btn-bg: #436ab4;
  --cui-btn-border-color: #436ab4;
  --cui-btn-hover-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-hover-bg: #4b78cb;
  --cui-btn-hover-border-color: #31569a;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-active-bg: #4b78cb;
  --cui-btn-active-border-color: #31569a;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-disabled-bg: #436ab4;
  --cui-btn-disabled-border-color: #436ab4;
}

.btn-danger {
  --cui-btn-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-bg: #ca4848;
  --cui-btn-border-color: #c44646;
  --cui-btn-hover-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-hover-bg: #d45151;
  --cui-btn-hover-border-color: #ad3f3f;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-active-bg: #d45151;
  --cui-btn-active-border-color: #ad3f3f;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-disabled-bg: #ad3f3f;
  --cui-btn-disabled-border-color: #ad3f3f;
}

.dark-theme .btn-danger {
  --cui-btn-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-bg: #ca4848;
  --cui-btn-border-color: #c44646;
  --cui-btn-hover-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-hover-bg: #d45151;
  --cui-btn-hover-border-color: #ad3f3f;
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-active-bg: #d45151;
  --cui-btn-active-border-color: #ad3f3f;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: hsla(0, 0%, 100%, 0.87);
  --cui-btn-disabled-bg: #ad3f3f;
  --cui-btn-disabled-border-color: #ad3f3f;
}

.dropItem {
  font-size: 0.8em;
  cursor: pointer;
  margin: 0;
}

.dropItem span {
  color: #222;
  padding: 0px;
  display: inline-block;
  width: 30px;
  text-align: center;
  font-size: 1.5em;
}
/* e: Color Change --------------------- */

/* s: Full Calendar --------------------- */
.full-calendar a {
  text-decoration: none;
  color: #333;
}

.full-calendar td.fc-daygrid-day:hover {
  background-color: #eae9e9;
  cursor: pointer;
}

/* e: Full Calendar --------------------- */

.svc-outline-btn {
  font-size: 0.8em;
  height: 37px;
}

.svc-outlin-cnt {
  font-weight: bold;
}

.svc-rev-rst-label {
  width: 6% !important;
}

.svcStatus {
  font-size: 0.9em;
  font-weight: bold;
  border: 1px solid #777;
  border-radius: 10px;
  padding: 4px 8px;
  opacity: 1;
  background-color: #c2c3c5;
  color: #181717;
}

/*
.loadingx:before {
  position: absolute;
  content: '';
  top: 0%;
  left: 0%;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  border-radius: 0.28571429rem;
  z-index: 100;
}

.loadingx:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -1.5em 0em 0em -1.5em;
  width: 3em;
  height: 3em;
  -webkit-animation: segment-spin 0.6s linear;
  animation: segment-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #223088 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0px 0px 0px 1px transparent;
  visibility: visible;
  z-index: 101;
}

@-webkit-keyframes segment-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes segment-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
*/

/*
input:disabled {
  cursor: default;
  background-color: rgba(190, 190, 192, 0.3) !important;
  color: rgb(151, 150, 150) !important;
  border-color: rgba(85, 77, 77, 0.3) !important;
}*/

/*
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
*/

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  width: "100px";
  padding: 5px 15px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  margin-top: 5px;
  transition: background-color 0.3s ease;
}

.custom-file-upload:hover {
  background-color: #0056b3;
}
